.container{
  position: absolute;
}

.bounding-box {
  position: absolute;
  box-shadow: 0 0 0 3px #fed60b inset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}
